import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
        meta: {
          title: 'CMS - Virtual Meeting Room | Dashboard',
          access_right: '1001'
        }
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue")
      },
      
      // ::members::
      {
        path: "/members",
        name: "members",
        redirect: "/members/list",
        component: () => import("@/view/pages/members/Member.vue"),
        meta:{
          title: 'CMS - Virtual Meeting Room | Anggota',
          access_right: '2'
        },
        children: [
          {
            path: "/members/list",
            name: "members-list",
            component: () => import("@/view/pages/members/List.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Anggota',
              access_right: '1001'
            },
          },
          {
            path: "/source-person/list",
            name: "source-person-list",
            component: () => import("@/view/pages/members/List-narasumber.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Narasumber',
              access_right: '1001'
            },
          },
          {
            path: "/moderate/list",
            name: "moderate-list",
            component: () => import("@/view/pages/members/List-moderate.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Moderator',
              access_right: '1001'
            },
          },
          {
            path: "/coach/list",
            name: "coach-list",
            component: () => import("@/view/pages/members/List-coach.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Coach',
              access_right: '1001'
            },
          },
        
          {
            path: "/partner/list",
            name: "partner-list",
            component: () => import("@/view/pages/members/List-mitra.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Mitra',
              access_right: '1001'
            },
          },
          {
            path: "/members/add",
            name: "members-add",
            component: () => import("@/view/pages/members/Add.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Peserta',
              access_right: '1001'
            },
          },
          {
            path: "/source-person/add",
            name: "source-person-add",
            component: () => import("@/view/pages/members/Add-narasumber.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Narasumber',
              access_right: '1001'
            },
          },
          {
            path: "/coach/add",
            name: "coach-add",
            component: () => import("@/view/pages/members/Add-coach.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Coach',
              access_right: '1001'
            },
          },
          {
            path: "/moderate/add",
            name: "moderate-add",
            component: () => import("@/view/pages/members/Add-moderate.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Moderator',
              access_right: '1001'
            },
          },
          {
            path: "/partner/add",
            name: "partner-add",
            component: () => import("@/view/pages/members/Add-mitra.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Mitra',
              access_right: '1001'
            },
          },
          {
            path: "/members/edit/:id",
            name: "members-edit",
            component: () => import("@/view/pages/members/Edit.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Anggota',
              access_right: '1001'
            },
          },
          {
            path: "/source-person/edit/:id",
            name: "source-person-edit",
            component: () => import("@/view/pages/members/Edit-narasumber.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Narasumber',
              access_right: '1001'
            },
          },
          {
            path: "/coach/edit/:id",
            name: "coach-edit",
            component: () => import("@/view/pages/members/Edit-coach.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Coach',
              access_right: '1001'
            },
          },
          {
            path: "/moderate/edit/:id",
            name: "moderate-edit",
            component: () => import("@/view/pages/members/Edit-moderate.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Moderator',
              access_right: '1001'
            },
          },
          {
            path: "/partner/edit/:id",
            name: "partner-edit",
            component: () => import("@/view/pages/members/Edit-mitra.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Mitra',
              access_right: '1001'
            },
          },
          {
            path: "/members/detail/:id",
            name: "members-detail",
            component: () => import("@/view/pages/members/Detail.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Anggota',
              access_right: '1001'
            },
          },
        ]
      },
      // ::promotion-medias::
      {
        path: "/promotion-medias",
        name: "promotion-medias",
        redirect: "/promotion-medias/setting",
        component: () => import("@/view/pages/promotion-medias/PromotionMedia.vue"),
        meta:{
          title: 'CMS - Virtual Meeting Room | Pengaturan Media Promosi',
          access_right: '2'
        },
        children: [
          // {
          //   path: "/promotion-medias/list",
          //   name: "promotion-medias-list",
          //   component: () => import("@/view/pages/promotion-medias/List.vue"),
          //   meta:{
          //     title: 'CMS - Virtual Meeting Room | Media Promosi',
          //     access_right: '1001'
          //   },
          // },
          {
            path: "/promotion-medias/setting",
            name: "promotion-medias-add",
            component: () => import("@/view/pages/promotion-medias/Setting.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Pengaturan Media Promosi',
              access_right: '1001'
            },
          },
          // {
          //   path: "/promotion-medias/edit/:id",
          //   name: "promotion-medias-edit",
          //   component: () => import("@/view/pages/promotion-medias/Edit.vue"),
          //   meta:{
          //     title: 'CMS - Virtual Meeting Room | Media Promosi',
          //     access_right: '1001'
          //   },
          // },
          // {
          //   path: "/promotion-medias/detail/:id",
          //   name: "promotion-medias-detail",
          //   component: () => import("@/view/pages/promotion-medias/Detail.vue"),
          //   meta:{
          //     title: 'CMS - Virtual Meeting Room | Media Promosi',
          //     access_right: '1001'
          //   },
          // },
        ]
      },
      // ::pitching-events::
      {
        path: "/pitching-events",
        name: "pitching-events",
        redirect: "/pitching-events/list",
        component: () => import("@/view/pages/pitching-events/PitchingEvent.vue"),
        meta:{
          title: 'CMS - Virtual Meeting Room | Virtual Event',
          access_right: '2'
        },
        children: [
          {
            path: "/pitching-events/list",
            name: "pitching-events-list",
            component: () => import("@/view/pages/pitching-events/List.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
          {
            path: "/pitching-events/add",
            name: "pitching-events-add",
            component: () => import("@/view/pages/pitching-events/Add.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
          {
            path: "/pitching-events/edit/:id",
            name: "pitching-events-edit",
            component: () => import("@/view/pages/pitching-events/Edit.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
          {
            path: "/pitching-events/detail/:id",
            name: "pitching-events-detail",
            component: () => import("@/view/pages/pitching-events/Detail.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
        ]
      },
      // ::pitching-video-conf-settings::
      {
        path: "/pitching-video-conf-settings",
        name: "pitching-video-conf-settings",
        redirect: "/pitching-video-conf-settings/list",
        component: () => import("@/view/pages/pitching-video-conf-settings/PitchingVideoConfSetting.vue"),
        meta:{
          title: 'CMS - Virtual Meeting Room | Virtual Event',
          access_right: '2'
        },
        children: [
          {
            path: "/pitching-video-conf-settings/list",
            name: "pitching-video-conf-settings-list",
            component: () => import("@/view/pages/pitching-video-conf-settings/List.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
          {
            path: "/pitching-video-conf-settings/add",
            name: "pitching-video-conf-settings-add",
            component: () => import("@/view/pages/pitching-video-conf-settings/Add.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
          {
            path: "/pitching-video-conf-settings/edit/:id",
            name: "pitching-video-conf-settings-edit",
            component: () => import("@/view/pages/pitching-video-conf-settings/Edit.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
          {
            path: "/pitching-video-conf-settings/detail/:id",
            name: "pitching-video-conf-settings-detail",
            component: () => import("@/view/pages/pitching-video-conf-settings/Detail.vue"),
            meta:{
              title: 'CMS - Virtual Meeting Room | Virtual Event',
              access_right: '1001'
            },
          },
        ]
      },
      // ::masters::
      {
        path: "/masters",
        name: "masters",
        redirect: "/masters/settings",
        component: () => import("@/view/pages/masters/Masters.vue"),
        meta: {
          title: 'CMS - Virtual Meeting Room | Data Pendukung - Setting'
        },
        children: [
          // setting
          {
            path: "/masters/settings",
            name: "master-setting",
            component: () => import("@/view/pages/masters/Setting.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Setting'
            },
          },

          // access-roles
          {
            path: "/masters/user-managements/role-access",
            name: "master-access-roles",
            component: () => import("@/view/pages/masters/user-managements/RoleAccess.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Hak Akses Peran',
              access_right: '9001'
            },
          },

          // access-users
          {
            path: "/masters/user-managements/user-access",
            name: "master-access-users",
            component: () => import("@/view/pages/masters/user-managements/UserAccess.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Hak Akses User',
              access_right: '9002'
            },
          },
          // roles
          {
            path: "/masters/roles",
            name: "master-roles",
            component: () => import("@/view/pages/masters/roles/Roles.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Role',
              access_right: '9027'
            },
          },
          {
            path: "/masters/roles/add",
            name: "master-roles-add",
            component: () => import("@/view/pages/masters/roles/RolesAdd.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Role',
              access_right: '9028'
            },
          },
          {
            path: "/masters/roles/edit/:id",
            name: "master-roles-edit",
            component: () => import("@/view/pages/masters/roles/RolesUpdate.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Peran',
              access_right: '9028'
            },
          },
          {
            path: "/masters/roles/detail/:id",
            name: "master-roles-detail",
            component: () => import("@/view/pages/masters/roles/RolesDetail.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - Peran',
              access_right: '9027'
            },
          },

          // users
          {
            path: "/masters/users",
            name: "master-users",
            component: () => import("@/view/pages/masters/users/Users.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - User',
              access_right: '9001'
            },
          },
          {
            path: "/masters/users/add",
            name: "master-users-add",
            component: () => import("@/view/pages/masters/users/UsersAdd.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - User',
              access_right: '9002'
            },
          },
          {
            path: "/masters/users/detail/:id",
            name: "master-users-detail",
            component: () => import("@/view/pages/masters/users/UsersDetail.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - User',
              access_right: '9001'
            },
          },
          {
            path: "/masters/users/edit/:id",
            name: "master-users-edit",
            component: () => import("@/view/pages/masters/users/UsersUpdate.vue"),
            meta: {
              title: 'CMS - Virtual Meeting Room | Data Pendukung - User',
              access_right: '9002'
            },
          },
        ]
      },

      // blankboard
      {
        path: "/blankboard",
        name: "blankboard",
        component: () => import("@/view/pages/Blankboard.vue"),
        meta: {
          title: 'Blankboard'
        }
      },
      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/view/pages/custom_pages/Profile.vue"),
        meta: {
          title: 'CMS - Virtual Meeting Room | Profil'
        }
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Login"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login.vue"),
        meta: {
          title: 'CMS - Virtual Meeting Room | Login'
        }
      },
      {
        name: "login-1",
        path: "/login-1",
        component: () => import("@/view/pages/auth/login_pages/Login-1"),
        meta: {
          title: 'CMS - Virtual Meeting Room | Login'
        }
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: 'CMS - Virtual Meeting Room | Register'
        }
      }
    ]
  },
  {
    name: "attendance-confirmation",
    path: "/attendance-confirmation",
    component: () => import("@/view/pages/attendance-confirmation/Confirmation.vue"),
    meta: {
      title: 'CMS - Virtual Meeting Room | Konfirmasi Kehadiran'
    }
  },
  {
    name: "scores",
    path: "/scores",
    component: () => import("@/view/pages/general/Score.vue"),
    meta: {
      title: 'CMS - Virtual Meeting Room | Skor'
    }
  },
  {
    name: "update-profile",
    path: "/update-profile/:id",
    component: () => import("@/view/pages/attendance-confirmation/Update_profile.vue"),
    meta: {
      title: 'CMS - Virtual Meeting Room | Konfirmasi Kehadiran'
    }
  },
  // redirect to 404
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      title: 'CMS - Virtual Meeting Room | Page Not Found'
    },
    component: () => import("@/view/pages/error/Error-1.vue")
  },

  // 503
  {
    path: '/access-forbidden',
    name: 'access-forbidden',
    meta: {
      title: 'CMS - Virtual Meeting Room | Access Forbidden'
    },
    component: () => import('@/view/pages/error/Error-7.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route Middleware
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.access_right)) {
//     let localStorageAccessRight = JSON.parse(localStorage.getItem('access_right_display'))
//     if (localStorageAccessRight != null) {
//       let result = false
//       for (let i = 0; i < localStorageAccessRight.length; i++) {
//         result = false
//         if (localStorageAccessRight[i] == to.meta.access_right) {
//           result = true
//           break
//         }
//       }
//       if (result) {
//         next()
//       } else {
//         next('/access-forbidden')
//       }
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router;
