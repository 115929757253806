import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
Vue.use(vuetify)
import Swal from 'sweetalert2'
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";

// primevue
import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);                         
import "primevue/resources/themes/saga-blue/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"

import DatetimePicker from 'vuetify-datetime-picker';
Vue.use(DatetimePicker)

// mobile detection
import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

// export to xlsx
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

// vue print html
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}
Vue.use(VueHtmlToPaper, options);

// apexchart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

// SweetAlert
window.Swal = Swal
window.SwalLoading = Swal.mixin({
  title: 'Loading',
  text: 'Sedang memproses data',
  icon: 'info',
  heightAuto: false,
  allowOutsideClick: false,
  allowEscapeKey: false,
  showConfirmButton: false,
  willOpen: () => {
    Swal.showLoading()
  }
})

// text editor
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

// import CKEditor4 from 'ckeditor4-vue';
// Vue.use( CKEditor4 );

import emoji from "vueditor/dist/plugins/emoji.min.js"

import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
let plugins = [
  {
    name: 'emoji',
    element: {
      type: 'button',
      lang: {
        title: 'Emoji'
      },
      className: 'icon-smile-o'
    },
    // emoji loaded from script tag, line 35
    component: emoji
  }
]

let defaultConf = {
  spellcheck: true,
  toolbar: [
    'undo', 'redo', '|', 'foreColor', 'backColor', 'divider', 'bold', 'italic', 'underline', 'strikeThrough',
    'link', 'unLink', 'divider', 'subscript', 'superscript', 'divider',
    '|', 'insertOrderedList', 'insertUnorderedList', '|', 'emoji',
  ],
  plugins: plugins
}

Vue.use(Vueditor, defaultConf);

router.beforeEach((to, from, next) => {

document.title = to.meta.title
next()
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
